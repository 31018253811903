import React, { useEffect, useState } from "react";
// import ProductFilter from "./ProductFilter";
// import ProductSort from "./ProductSort";
// import productimg from "../../../assets/img/product_images/single_prod.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faHeart } from "@fortawesome/free-solid-svg-icons";
import { Hourglass } from "react-loader-spinner";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getBrandOptionsAPICall,
  getCategoriesOptionsAPICall,
  getCategoryProductsAPICall,
  getFilterProductsAPICall,
  priceFilterRangeGetAPICall,
} from "../../../service/apiService";
import "../../../assert/css/home.css";
import noImage from "../../../assert/Image/Screenshot from 2024-08-09 15-40-14.png";
import PaginationIndex from "../../../components/Paginations/PaginationIndex";

const CategoryPage = () => {
  const urlParams = useParams();
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [brandOptions, SetBrandOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [noMatchFound, setNoMatchFound] = useState(false);
  const [priceRangeData, setPriceRangeData] = useState([]);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const navigate = useNavigate();
  const productsPerPage = 20;
  const location = useLocation();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { productID } = location.state || {};
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  // useEffect(() => {
  //     getAllProducts();
  // }, [])
  useEffect(() => {
    getAllProducts();
    getCategoriesOptions();
    getAllgetBrandOptions();
    priceFilterValueGet();
  }, [urlParams.categoryName]);

  const singlePageRedirection = (id) => {
    navigate(`/product_detail`, { state: { id } });
  };

  const getAllProducts = async () => {
    try {
      setIsLoading(true);
      const response = await getCategoryProductsAPICall(productID);
      setIsLoading(false);
      const status = await response.status_code;
      if (status === 200) {
        setProducts(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(products.length / productsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const gotoFirstPage = () => {
    setCurrentPage(1);
  };

  const getCategoriesOptions = async () => {
    try {
      const response = await getCategoriesOptionsAPICall();
      const status = await response.status_code;
      if (status === 200) {
        setCategoriesOptions(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAllgetBrandOptions = async () => {
    try {
      const response = await getBrandOptionsAPICall();
      const status = await response.status_code;
      if (status === 200) {
        SetBrandOptions(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const categoriesOnchange = async (categoryId) => {
    let categoryIDValue = selectedCategory === categoryId ? 0 : categoryId;
    let BrandIDValue = selectedBrand === null ? 0 : selectedBrand;
    if (selectedCategory === categoryId) {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(categoryId);
    }
    if (categoryIDValue !== 0 || BrandIDValue !== 0) {
      try {
        const response = await getFilterProductsAPICall(
          categoryIDValue,
          BrandIDValue
        );
        if (response) {
          setProducts(response.data || []);
          setNoMatchFound(false);
          if (response.data == null) {
            setNoMatchFound(true);
          }
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      getAllProducts();
    }
  };

  const brandsOnchange = async (brandId) => {
    let categoryIDValue = selectedCategory === null ? 0 : selectedCategory;
    let BrandIDValue = selectedBrand === brandId ? 0 : brandId;
    if (selectedBrand === brandId) {
      setSelectedBrand(null);
    } else {
      setSelectedBrand(brandId);
    }

    if (categoryIDValue !== 0 || BrandIDValue !== 0) {
      try {
        const response = await getFilterProductsAPICall(
          categoryIDValue,
          BrandIDValue
        );
        if (response) {
          setProducts(response.data || []);
          setNoMatchFound(false);
          if (response.data == null) {
            setNoMatchFound(true);
          }
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      getAllProducts();
    }
  };

  const priceFilterOnchange = async (e) => {
    let categoryIDValue = selectedCategory === null ? 0 : selectedCategory;
    let BrandIDValue = selectedBrand === null ? 0 : selectedBrand;
    const { name, value } = e.target;
    if (name === "range-min") {
      if (parseInt(value) <= maxPrice) {
        setMinPrice(parseInt(value));
        try {
          const response = await getFilterProductsAPICall(
            categoryIDValue,
            BrandIDValue,
            value,
            maxPrice
          );
          if (response) {
            setProducts(response.data || []);
            setNoMatchFound(false);
            setCurrentPage(1);
            if (response.data == null) {
              setNoMatchFound(true);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    } else if (name === "range-max") {
      if (parseInt(value) >= minPrice) {
        setMaxPrice(parseInt(value));
        try {
          const response = await getFilterProductsAPICall(
            categoryIDValue,
            BrandIDValue,
            minPrice,
            value
          );
          if (response) {
            setProducts(response.data || []);
            setNoMatchFound(false);
            setCurrentPage(1);
            if (response.data == null) {
              setNoMatchFound(true);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const priceFilterValueGet = async () => {
    try {
      const response = await priceFilterRangeGetAPICall();
      if (response.status_code === 200) {
        setPriceRangeData(response.data);
        setMinPrice(response.data.min_price_amount);
        setMaxPrice(response.data.max_price_amount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleShowModal = (productId, product) => {
    setSelectedProduct(product);
    navigate(`/product_detail`, { state: { id: productId } });
    setShowModal(true);
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts =
    products !== null
      ? products.slice(indexOfFirstProduct, indexOfLastProduct)
      : [];

  return (
    <>
      <section className="section_breadcrumb section_padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="breadcrumb">
                <li>
                  <Link to="/">Home /</Link>
                </li>
                <li>
                  <Link>Shop</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="section_p_f_fil_cont section_padding_py">
        <div className="container">
          <div className="row s_p_f_f_c_par">
            <div className="col-lg-10 s_p_f_f_c_right">
              <div className="row m-0 prdct_card_par">
                {isLoading ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="loader"
                        style={{ width: "200px", height: "200px" }}
                      >
                        <Hourglass color="#00BFFF" height={200} width={200} />
                      </div>
                    </div>
                    <h2 style={{ textAlign: "center" }}>Loading......</h2>
                  </>
                ) : (
                  currentProducts &&
                  currentProducts.length > 0 &&
                  currentProducts.map((product) => (
                    <div className="col-md-3 sing_prod_card" key={product.id}>
                      <div className="static">
                        <div className="s_p_c_img">
                          <img
                            src={
                              product.thumnail_image.endsWith("undefined") ||
                              product.thumnail_image == ""
                                ? noImage
                                : AppUrl + product.thumnail_image
                            }
                            alt={product.product_name}
                            style={{ width: "100%", height: "270px" }}
                          />
                        </div>
                        <div className="s_p_c_con">
                          <h6>{product.brand_name}</h6>
                          <p>{product.product_name}</p>
                          <div className="price">
                            <span className="dis_pr">
                              Rs.
                              {product.discount_percentage != 0
                                ? product.discount_price
                                : product.original_price}
                            </span>
                            {product.discount_percentage != 0 ? (
                              <span>
                                <span className="og_pr">
                                  Rs.{product.original_price}
                                </span>
                                <span className="dis_per">
                                  {product.discount_percentage}% OFF
                                </span>
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="s_p_c_hov_con">
                        <img
                          src={
                            product.thumnail_image.endsWith("undefined") ||
                            product.thumnail_image == ""
                              ? noImage
                              : AppUrl + product.thumnail_image
                          }
                          alt={product.product_name}
                          style={{ width: "100%", height: "240px" }}
                          onClick={() =>
                            singlePageRedirection(product.product_id)
                          }
                        />

                        <div
                          key={product.product_id}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleShowModal(product.product_id, product);
                          }}
                          className="whishlist bg-success text-white"
                          style={{ cursor: "pointer" }}
                        >
                          Quick View
                        </div>
                        {/* <p>Sizes: {product.sizes.join(", ")}</p> */}
                        <div className="price">
                          <span className="dis_pr">
                            Rs.
                            {product.discount_percentage != 0
                              ? product.discount_price
                              : product.original_price}
                          </span>
                          {product.discount_percentage != 0 ? (
                            <span>
                              <span className="og_pr">
                                Rs.{product.original_price}
                              </span>
                              <span className="dis_per">
                                {product.discount_percentage}% OFF
                              </span>
                            </span>
                          ) : null}
                        </div>
                      </div>
                      {/* </Link> */}
                    </div>
                  ))
                )}
              </div>
              {!noMatchFound && (
                <PaginationIndex
                  totalProducts={products?.length}
                  productsPerPage={productsPerPage}
                  currentPage={currentPage}
                  gotoFirstPage={gotoFirstPage}
                  handleNextPage={handleNextPage}
                  handlePreviousPage={handlePreviousPage}
                />
              )}
            </div>
            {!isLoading && noMatchFound && (
              <div style={{ textAlign: "center", fontWeight: "bold" }}>
                No match found...
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default CategoryPage;
