import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { downloadInvoice } from "./downloadInvoice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import { getmyOrderList } from "../../service/apiService";
import "../../assert/css/pagenation.css";

const OrdersList = () => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const settingsData = useSelector((state) => state.settings.settingsData);
  const loginLogo = settingsData.find(
    (setting) => setting.key_name === "SITE_LOGO"
  );
  let loginPageImg = "";
  if (loginLogo && loginLogo.key_value) {
    loginPageImg = AppUrl + loginLogo.key_value;
  }
  const addressLinkOne = settingsData.find(
    (item) => item.key_name === "ADDRESS_ONE"
  )?.key_value;
  const phoneNumberone = settingsData.find(
    (item) => item.key_name === "PHONE_ONE"
  )?.key_value;
  const emailOne = settingsData.find(
    (item) => item.key_name === "EMAIL_ONE"
  )?.key_value;
  const phoneNumbertwo = settingsData.find(
    (item) => item.key_name === "PHONE_TWO"
  )?.key_value;
  const emailTwo = settingsData.find(
    (item) => item.key_name === "EMAIL_TWO"
  )?.key_value;
  const gstNo = settingsData.find(item => item.key_name==="GST_NO")?.key_value
  const [orderList, setOrderList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const getAllOrderList = async () => {
    try {
      const response = await getmyOrderList(token);

      setOrderList(response.data);
    } catch (error) {}
  };
  useEffect(() => {
    getAllOrderList();
  }, []);

  const totalPages = Math.ceil(orderList?.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Array.isArray(orderList)
    ? orderList.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const getStatusClass = (status) => {
    switch (status) {
      case "pending":
        return "badge bg-warning";
      case "processing":
        return "badge bg-primary";
      case "shipping":
        return "badge bg-info";
      case "delivered":
        return "badge bg-success";
      case "cancelled":
        return "badge bg-danger";
      case "return_request":
        return "badge bg-dark";
      case "returned":
        return "badge bg-secondary";
      default:
        return "badge bg-secondary";
    }
  };
  const handleDownload = async (orderId) => {
    await downloadInvoice(
      orderId,
      loginPageImg,
      addressLinkOne,
      phoneNumberone,
      phoneNumbertwo,
      emailOne,
      emailTwo,
      gstNo
    );
  };
  return (
    <section className="padding-section">
      <div className="container">
        <div className="account-card">
          <div className="table-responsive table-style-1">
            <table className="table table-hover mb-3">
              <thead>
                <tr>
                  <th>Order</th>
                  <th>Date Purchased</th>
                  <th>Status</th>
                  <th>Total</th>
                  <th>Action</th>
                  <th>Invoice</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item) => (
                  <tr key={item.order_id}>
                    <td>
                      <span
                        onClick={() =>
                          navigate("/order-details/view", {
                            state: { id: item.order_id },
                          })
                        }
                        className="fw-medium"
                      >
                        {item.order_code}
                      </span>
                    </td>
                    <td>{item.order_date}</td>
                    <td>
                      <span className={getStatusClass(item.order_status)}>
                        {item.order_status}
                      </span>
                    </td>
                    <td>{item.total_amount}</td>
                    <td>
                      <span
                        onClick={() =>
                          navigate("/view", {
                            state: { id: item?.order_id },
                          })
                        }
                        className="btn text-underline p-0"
                      >
                        <FontAwesomeIcon
                          icon={faEye}
                          style={{ width: "50px" }}
                        />
                      </span>
                    </td>
                    <td>
                      <span onClick={() => handleDownload(item.order_id)}>
                        <FontAwesomeIcon
                          icon={faDownload}
                          style={{ width: "50px" }}
                        />
                      </span>{" "}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="d-flex justify-content-center">
            <nav aria-label="Table Pagination">
              <ul className="pagination style-1">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <a
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Prev
                  </a>
                </li>
                {[...Array(totalPages)].map((_, index) => (
                  <li
                    key={index + 1}
                    className={`page-item ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                  >
                    <a
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </a>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OrdersList;
